import {Button, Col, Descriptions, Form, Input, message, Row} from "antd";
import {useForm} from "antd/es/form/Form";
import dayjs from "dayjs";
import React, {useContext, useEffect, useState} from "react";
import {BrowserView, MobileView} from 'react-device-detect';
import {DocumentTitle} from "./DocumentTitle";
import {useParams} from "react-router";
import {AppContextContext, AuthenticatorServiceContext} from "../Contexts";
import {useIntlMessage} from "../createIntlMessage";
import Authenticator from "../domain/Authenticator";
import {ServerConstraintViolationsHolder} from "../sal-ui/ServerConstraintViolations";
import styles from "./RegisterAuthenticator.module.css";

enum RegistrationFormState {
    REGISTRATION,
    DONE
}

function RegisterAuthenticator() {
    const appContext = useContext(AppContextContext);
    const intlMessage = useIntlMessage("register-authenticator");
    const authenticatorService = useContext(AuthenticatorServiceContext);
    const [authenticator, setAuthenticator] = useState<Authenticator>();
    const {invitationId, url, path}: any = useParams();
    const [finishRegistrationForm] = useForm();
    const [registrationState, setRegistrationState] = useState<RegistrationFormState>(RegistrationFormState.REGISTRATION);

    const serverViolationsHolder = new ServerConstraintViolationsHolder();

    const layout = {xs: 24, sm: 24, md: 16, lg: 14, xl: 10, xxl: 10};

    useEffect(() => {
        authenticatorService.findByInvitationId(invitationId!).then(setAuthenticator)
    }, []);

    return (
        <DocumentTitle title={`${appContext.config?.appName}: ${intlMessage("title")}`}>
            <Row justify={"center"}>
                <Col {...layout}>
                    <h1>{intlMessage("title")}</h1>

                    {!authenticator &&
                    intlMessage("request-not-found")
                    }

                    {registrationState === RegistrationFormState.DONE &&
                    intlMessage("register-authenticator-successful")
                    }

                    {registrationState === RegistrationFormState.REGISTRATION && authenticator &&
                    <>
                        <Row>
                            <Col>

                                <BrowserView>
                                    <p>
                                        {authenticator.type === "MOBILE" && intlMessage("register-mobile-authenticator-help_pc")}
                                        {authenticator.type === "YUBIKEY" && intlMessage("register-yubikey-authenticator-help_pc")}
                                    </p>
                                </BrowserView>
                                <MobileView>
                                    <p>
                                        {authenticator.type === "MOBILE" && intlMessage("register-mobile-authenticator-help_mobile")}
                                        {authenticator.type === "YUBIKEY" && intlMessage("register-yubikey-authenticator-help_mobile")}
                                    </p>
                                </MobileView>
                            </Col>
                        </Row>
                        <Descriptions column={1} bordered={true} className={`common__details ${styles.details}`} size="small">
                            <Descriptions.Item label={intlMessage("authenticator")}>{authenticator.name}</Descriptions.Item>
                            {authenticator.type === "MOBILE" && <Descriptions.Item label={intlMessage("phone-number")}>{authenticator.phoneNumber}</Descriptions.Item>}
                            <Descriptions.Item label={intlMessage("createdAt")}>{dayjs(authenticator?.createdAt).format('YYYY-MM-DD HH:mm:ss')}</Descriptions.Item>
                            <Descriptions.Item label={intlMessage("invitationExpiresAt")}>{dayjs(authenticator?.invitationExpiresAt).format('YYYY-MM-DD HH:mm:ss')}</Descriptions.Item>
                        </Descriptions>

                        {authenticator.type === "MOBILE" &&
                        <>
                            <BrowserView>
                                <img src={`/api/user/authenticators/${authenticator?.id}/invitation-qr-code`}/>
                            </BrowserView>
                            <MobileView>
                                <a href={authenticator?.registerUrl} className={"ant-btn ant-btn-primary"}>{intlMessage("register-button")}</a>
                            </MobileView>

                            <div className={styles.stores}>
                                <p>{intlMessage("register-authenticator.mobile-stores-help")}</p>

                                <a href={`https://play.google.com/store/apps/details?id=cz.sonpo.element2.mobile&hl=${appContext.language}`}><img src={`/img/s-play-${appContext.language}.png`} /></a>
                                <a href={`https://apps.apple.com/cz/app/2element/id1561478893?l=${appContext.language}`}><img src={`/img/s-store-${appContext.language}.png`} /></a>
                            </div>
                        </>}

                        {authenticator.type === "YUBIKEY" &&
                        <Form form={finishRegistrationForm} onFinish={onFinishRegistration}>
                            <Form.Item
                                name={"yubiKeyOtp"}
                                label={intlMessage("yubi-key-otp")}
                                extra={intlMessage("yubi-key-otp-help")}
                                rules={[
                                    {required: true, message: intlMessage("yubi-key-otp-required")},
                                    {validator: serverViolationsHolder.createServerValidator('CUSTOM'), message: intlMessage("yubi-key-otp-not-valid")},
                                    {validator: serverViolationsHolder.createServerValidator('UNIQUE'), message: intlMessage("yubi-key-otp-already-registered")}
                                ]}>
                                <Input autoFocus={true}/>
                            </Form.Item>

                            <Form.Item className={"common__form-buttons"}>
                                <Button type={"primary"} htmlType={"submit"}>{intlMessage('add-authenticator')}</Button>
                            </Form.Item>
                        </Form>
                        }
                    </>
                    }
                </Col>
            </Row>
        </DocumentTitle>
    )

    function onFinishRegistration(values: any) {
        authenticatorService.registerYubiKeyAuthenticator(authenticator?.id!, values.yubiKeyOtp)
            .then(
                () => {
                    message.info(intlMessage("authenticator-registration-finished"));
                    setRegistrationState(RegistrationFormState.DONE);
                },
                error => {
                    if (error && error.response && error.response.data && serverViolationsHolder.isConstraintViolations(error.response.data)) {
                        serverViolationsHolder.violations = error.response.data;

                        finishRegistrationForm.validateFields();
                    }

                    return Promise.reject(error);
                });
    }
}

export default RegisterAuthenticator;
