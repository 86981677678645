import 'mobx-react-lite/batchingForReactDom'
import React from 'react';
import ReactDOM from 'react-dom';
import {BrowserRouter} from "react-router-dom";
import App from './App';
import {appContext, preferenceService} from "./Contexts";
import './index.css';
import {loadMessages, updateLocaleResources} from "./Intl";
import AxiosService from "./service/AxiosService";
import {HelmetProvider} from "react-helmet-async";
import {AppConfig} from "./AppConfig";
import Axios from "axios";
import PreferenceNamespace from "./service/PreferenceNamespace";
import "dayjs/locale/en";
import "dayjs/locale/cs";

AxiosService.setUpInterceptors(appContext);

export const APP_NAME = "2Element";

Axios.get("/app-config").then(response => {
    appContext.config = AppConfig.fromPOJO(response.data);

    appContext.language = preferenceService.getItem(PreferenceNamespace.SettingsLanguage) || appContext.config.defaultLanguage || "en";

    updateLocaleResources(appContext.language);

    loadMessages().then(() => {
        ReactDOM.render(
            <HelmetProvider>
                <BrowserRouter basename={"/user/"}>
                    <App/>
                </BrowserRouter>
            </HelmetProvider>,
            document.getElementById('root')
        );
    });
});

